/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import '@syncfusion/ej2-base/styles/material.css';
@import '@syncfusion/ej2-buttons/styles/material.css';
@import '@syncfusion/ej2-dropdowns/styles/material.css';
@import '@syncfusion/ej2-inputs/styles/material.css';
@import '@syncfusion/ej2-navigations/styles/material.css';
@import '@syncfusion/ej2-popups/styles/material.css';
@import '@syncfusion/ej2-splitbuttons/styles/material.css';
@import '@syncfusion/ej2-pdfviewer/styles/material.css';
@import '@syncfusion/ej2-notifications/styles/material.css';

@import '@syncfusion/ej2-base/styles/material.css';
@import '@syncfusion/ej2-inputs/styles/material.css';
@import '@syncfusion/ej2-buttons/styles/material.css';
@import '@syncfusion/ej2-splitbuttons/styles/material.css';
@import '@syncfusion/ej2-lists/styles/material.css';
@import '@syncfusion/ej2-navigations/styles/material.css';
@import '@syncfusion/ej2-popups/styles/material.css';
@import '@syncfusion/ej2-dropdowns/styles/material.css';
@import '@syncfusion/ej2-spreadsheet/styles/material.css';
@import '@syncfusion/ej2-grids/styles/material.css';

.lds-ring {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
  }
  .lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #fff;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
  }
  @keyframes lds-ring {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
  }
  
  .mat-form-field.mat-form-field-appearance-fill.mat-focused:not(.mat-form-field-invalid) .mat-form-field-wrapper .mat-form-field-flex {
	border-color: rgb(74 222 128) !important;
  }
  
  
  @media print {
	body {
	  print-color-adjust: exact;
	  -webkit-print-color-adjust: exact;
	}
  }
  
  table {
	@apply w-full bg-card dark:bg-transparent;
  }
  
  thead {
	@apply text-gray-500 border-b-2 border-slate-100;
  }
  
  th {
	@apply p-4 font-semibold text-md whitespace-pre lg:whitespace-normal bg-[#F8FAFC] dark:bg-graydm dark:text-white;
  }
  
  td {
	@apply p-4 font-normal text-center border-b-[1px] whitespace-pre lg:whitespace-normal bg-transparent dark:bg-transparent text-black dark:text-white;
  }
  
  tfoot {
	@apply text-gray-500 border-t-2 border-slate-100 bg-[#F8FAFC] dark:bg-card dark:text-white;
  
  }
  
  tfoot td {
	@apply p-4 font-semibold text-md whitespace-pre lg:whitespace-normal bg-[#F8FAFC] dark:bg-graydm dark:text-white;
  }
  
  // apply to mat paginator
  .mat-paginator-container {
	@apply bg-[#F8FAFC] dark:bg-graydm dark:text-white;
  }
  
  .mat-tab-group .mat-tab-body-content {
	padding: 0 !important;
  }
  
  .mat-tab-group .mat-tab-header .mat-tab-label-container {
	padding: 10px 20px !important;
  }
  
  .no-scrollbar {
	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  
  .no-scrollbar::-webkit-scrollbar {
	display: none; /* Safari and Chrome */
  }

  .custom-spreadsheet .e-sheet-panel {
	height: 850px;
  }


  .mat-mdc-tab-body-content {
	padding: 0 !important;
  }

  .mat-mdc-dialog-title::before {
    content: none !important;
    font-size: 18px !important;
    font-weight: bold !important;
  }

  .mat-mdc-dialog-title    {
    font-size: 18px !important;
    font-weight: bold !important;
  }

  mat-error {
    margin-top: 0 !important;
  }

  mat-hint {
    margin-top: 0 !important;
  }


.mat-mdc-form-field-error-wrapper {
    margin-top: 0 !important;
    position: static !important;
}

.mat-mdc-form-field-bottom-align::before{
    content: none !important;
}

.mat-mdc-form-field{
  height: fit-content !important;
}

.mat-mdc-form-field-subscript-wrapper{
  padding-top: 0 !important;
  padding-bottom: 8px !important;
}